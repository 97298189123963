.description {
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;


    font-size: 2rem;
    padding-top: 80px;
    padding-bottom: 80px;
}

.description>div {
    width: 50%;
}

@media screen and (max-width: 1400px){
    .description > div {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .description > div {
        width: 100%;
        font-size: 1.5rem;
        padding-left: 15px;
        padding-right: 15px;
    }
}