:root {
  --and-green:#61a60e;
}


html,body
{
  width: 100%;
  min-height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Open Sans,Helvetica,Roboto,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.nav-footer-logo-container {
  max-width: 300px;
}

.nav-footer-logo-container > a > img {
  width: 100%;
}

.nav-footer-padding {
  padding-left: 200px;
  padding-right: 200px;
}

@media screen and (max-width: 1000px) {
  .nav-footer-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav-footer-logo-container {
    max-width: 200px;
  }
}

@media screen and (max-width: 1440px) {
  .nav-footer-padding {
    padding-left: 50px;
    padding-right: 50px;
  }
}

#root {
  display: flex;
  flex-direction: column;
}

.navigation {
  order:1;
  flex-shrink: 1;
}

.page-content {
  order: 2;
  flex-grow: 5;
}

.footer {
  order:3;
  flex-shrink: 1;
}

.btn {
  background-color: var(--and-green);
  border: var(--and-green);
  width: 200px;
  padding: 10px;
}

.btn:hover {
  background-color: var(--and-green);
  filter:brightness(90%);
}