.episode-display {
    background-image: url("../../assets/Anden-Single-Petal.png");
    background-repeat: repeat;
    background-size: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.episode-header {
    display: flex;
    justify-content: center;
    align-items: center;

    color:var(--and-green);
    font-size: 40px;
}

.ep-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
}

.ep-container img {
    width: 100%;
    max-width: 400px;
    max-height: 240px; /*probably don't need to hardcode this */
    border: var(--and-green) 1px solid;
}

.episode-id {
    padding-top: 5px;
}

.ep-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-row-gap: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
}

.episode-content {
    color:white;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    min-height: 450px;
}

.episode-title {
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 15px;
}

.play-container {
    position: relative;
}

.play-container img {
    display: block;
}

.play-container .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
}

@media screen and (max-width: 1050px) {
    .ep-row {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-row-gap: 0px;
    }
}

@media screen and (max-width: 768px) {
    .ep-row {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .episode-display .container {
        max-width: 540px;
    }

    .play-container {
        display: flex;
        justify-content: center;
    }

    .episode-content .episode-id {
        padding-top: 10px;
    }

}