.social-banner-body {
    background-image: url("../../assets/Anden-Single-Petal.png");
    background-repeat: repeat;
    background-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}

.built-for-grow {
    color:var(--and-green);
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.08rem;
    padding-bottom: 20px;
}

.social-images > a{
    text-decoration: none;
}

.social-icon {
    font-size:24px;
    color: #555;
    margin: auto;
    display: block !important;

   position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.social-images .btn {
    width: 60px;
    height: 60px;
    border-radius: 50%;

    background-color: transparent;
    border: #555 1px solid;

    margin-left: 20px;

    position: relative;
    cursor: pointer;
}

.social-images .btn:hover .social-icon {
    color: white;
}

.social-images > a:hover {
    background-color: var(--and-green);
}

@media screen and (max-width: 400px) {
    .social-images .btn {
        margin-left: 20px;
        margin-right: 20px;
        height: 40px;
        width: 40px;
    }

    .social-icon {
        font-size: 18px;
    }
}