.carousel  {
    border-bottom: #61a60e solid 19px;
}

.banner-text-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    position: absolute;
    left: 10%;
    top: 30%;
    bottom: 0;

    color: white;
}

.banner-text {
    display: flex;
    justify-content: flex-start;
    letter-spacing: 0.05rem;
}

.title {
    font-weight: bold;
    font-size: 72px;
    line-height: 80px;
}

.episode-number {
    font-weight: bold;
    font-size: 28px;
}

.banner-text-container .button {

    border: none;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px -4px;
    cursor: pointer;

    transition: 500ms;

    background-color: white;
    border-radius: 0.4rem;
    color: black;
    width: 150px;
    font-weight: bold;
}

a.button:hover{
    background-color: var(--and-green);
    filter:brightness(90%);
}

.carousel.carousel-slider li.slide.selected {
    z-index: 0 !important;
}

.banner .slider {
    max-height: 600px;
}

@media screen and (max-width: 1000px) {
    .title {
        font-size: 40px;
        line-height: 45px;
    }
    .episode-number {
        font-size: 18px;
    }
}

@media screen and (max-width: 800px) {
    .banner-text-container {
        left: 20%;
        top: 20%;
    }
}

@media screen and (max-width: 500px) {
    .title {
        font-size: 30px;
        line-height: 35px;
    }
    .episode-number {
        font-size: 14px;
    }

    .banner-text-container .button {
        font-size: 12px;
        padding: 6px 0;
    }

}
