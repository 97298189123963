.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding-top: 25px;
}

.videoContainer {
    max-width: 1000px;
    padding-left: 20px;
    padding-right: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video > iframe {
    width: 800px;
    height: 450px;
}

.videoInfo {
    color:white;

}

.videoHeader {
    font-size: 2rem;
    padding-bottom: 15px;
    padding-top:10px;
}

.videoDesc {
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 800px;
}

.videoInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.episodesNav {
    display:flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.episodesNav > .btn {
    background-color: var(--and-green);
    border: var(--and-green);
    width: 200px;
    padding: 10px;
}

.episodesNav > .btn >.arrow {
    font-size:20px;
}

.loading {
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

@media screen and (min-width: 2000px) {
    .outer-container {
        height: 80vh;
    }
}

@media screen and (max-width: 1000px) {

    .videoContainer {
        max-width: 800px;
        height: auto;
    }

    .video > iframe {
        width: 80%;
        height: 400px;
    }
    .episodesNav > .btn >.arrow {
        display: none;
    }

    .videoDesc {
        width: 80%;
    }
}

@media screen and (max-width: 800px) {
    .videoContainer {

    }

    .videoInfo {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width: 500px) {
    .videoContainer {
        padding-left: 10px;
        padding-right: 10px;
    }

    .episodesNav > .btn {
        width: 150px;
        padding: 7px;
    }

    .videoHeader {
        font-size: 1.5rem;
    }

    .videoDesc {
        font-size: 1rem;
    }

    .video > iframe {
        height: 300px;
    }
}
