.baked-container {
    background: url('../../assets/randy.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 80vh;
    width: 100%;
}

.baked-container .text {
    color: white;
    font-size: 40px;
    position: absolute;
    top: 40%; right: 60%;
    transform: translate(50%,-50%);
}

.baked-container .btn {
    background-color: var(--and-green);
    border-color: var(--and-green);
    color: black;

    padding:20px;
    font-size: 20px;
    font-weight: bold;

    position: absolute;
    top: 71%; right: 25%;
    transform: translate(50%,-50%);
}