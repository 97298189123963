.cultivators {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: black;

    padding: 40px 30px;
}

.cultivator {
    display: flex;
    padding: 40px 40px;
    color: white;
}

.cultivator .left-column {
    min-height: 250px;

    display: flex;
    flex-direction: column;
    flex-basis: 33%;
}

.left-column > div {
    font-size: 40px;
    font-weight: bold;
    color: var(--and-green);
}

.cult-img-container {
    padding-top: 20px;
}

.cult-img-container > img{
    max-width: 290px;
}

.cult-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;

    flex-basis: 66%;
}

.cult-content .cult-desc {
    font-size: 1.8rem;
}

.cult-content .cult-learn {
    padding-top: 30px;
}

@media screen and  (max-width: 1100px) {
    .cult-content .cult-desc {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 768px) {
    .cultivator {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .cult-desc {
        text-align: center;
    }
}
