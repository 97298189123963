.chapters {
    background-color: black;
    padding-top: 25px;
    color: white;
    padding-left: 25px;
    padding-right: 25px;
}

.chapter-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-row-gap: 15px;
    padding-top: 50px;
    align-items: start;
}

.chapter-content:nth-last-of-type(1) {
    padding-bottom: 50px;
}


.chap-sidebar {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;

    grid-row: 1/last-line;
    grid-row-start: span 3;
}


.play-container > img {
    width: 100%;
    border: var(--and-green) 1px solid;
}

.chapter-img > img {
    max-width: 250px;
}

.play-container > .play-icon {
    font-size: 48px;
}
.chapter-episode {
    width: 300px;
}

.chap-sidebar {
    grid-area: 1/1/3/2;
}

.chapter-header {
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 10px;
}

@media screen and (max-width: 1300px) {
    .chapter-episode {
        width: 250px;
    }

    .play-container > .play-icon {
        font-size: 36px;
    }

    .chapter-episode > .episode-title {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1200px) {
    .chapter-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-row-gap: 20px ;
    }

    .chap-sidebar {
        grid-row-start: span 4;
    }
}

@media screen and (max-width: 868px) {
    .chapter-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-row-gap: 20px ;
    }

    .chap-sidebar {
        grid-row-start: span 6;
    }
}

@media screen and (max-width: 600px) {
    .chapter-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .chapter-episode {
        width: 350px;
        padding-top: 30px;
    }
}

@media screen and (max-width: 320px) {
    .chapter-episode {
        width: 250px;
    }
}