.navigation {
    display: flex;
    background: black;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: var(--and-green) 1px solid;
}


.nav-link {
    color: white;
    font-size: calc(15px + 1vmin);
}

.nav-link:hover {
    color:var(--and-green);
}

.collapse {
    justify-content: space-evenly;
}

a.nav-link.active {
    color:var(--and-green);
    filter:brightness(200%);
}

a.nav-link.active:hover {
    filter:brightness(100%);
}

@media screen and (max-width: 600px) {
    .nav-link {
        font-size: 25px;
    }

    .navigation {
        flex-direction: column;
    }

    .navbar {
        padding-top: 25px;
    }
}