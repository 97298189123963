.footer-container {
    background-color: black;
}

.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    background-color: black;
    border-top: 10px solid var(--and-green);
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 20px;
}


.link > a {
    color:#61a60e;
    text-decoration: none;
}

.copyright {
    color: white;
}

@media screen and (max-width: 800px) {
    .footer {
        flex-direction: column-reverse;
        gap: 20px;
    }

}